import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';

import Styles from './ImageFluid.styles';

const ImageStyled = styled(Img)`${Styles.image}`; // prettier-ignore

/**
 * ImageFluid component
 * @param {props} props - component props
 * @param {string} props.className modifier class
 * @param {Object} props.fluid fluid properties
 * @param {Object} props.styles style modifiers
 * @returns {JSX}
 */
const ImageFluid = ({
  className, fluid, objectPosition, style,
}) => (
  <ImageStyled
    fluid={fluid}
    className={className}
    style={style}
    fadeIn
    objectPosition={objectPosition}
  />
);

ImageFluid.propTypes = {
  className: PropTypes.string,
  fluid: PropTypes.object,
  objectPosition: PropTypes.string,
  style: PropTypes.object,
};

export default ImageFluid;
