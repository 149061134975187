import { css } from 'styled-components';

export default {
  wrapper: css`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  `,
};
