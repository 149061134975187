import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Styles from './ImageFluidWrapper.styles';

const ImageWrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

/**
 * ImageFluidWrapper component
 * @param {props} props - component props
 * @param {Array|element|function|Node} props.children component childs
 * @param {string} props.className modifier class
 * @param {Object} props.styles style modifiers
 * @returns {JSX}
 */
const ImageFluidWrapper = ({ children, className, style }) => (
  <ImageWrapper className={className} style={style}>
    {children}
  </ImageWrapper>
);

ImageFluidWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ImageFluidWrapper;
